import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords = [], title, image, type = 'website' }) {
	const data = useStaticQuery(graphql`
		query DefaultSEOQuery {
			site {
				siteMetadata {
					title
					description
				}
			}
			sscsLogo: file(relativePath: { eq: "sscs-alexsc.png" }) {
				publicURL
			}
		}
	`);

	const metaDescription = description || data.site.siteMetadata.description;

	keywords = [...keywords, 'IEEE', 'SSCS', 'Alexandria', 'AlexSC', 'Student', 'Chapter'];

	return (
		<Helmet
			htmlAttributes={{
				lang
			}}
			title={title}
			titleTemplate={`%s | ${data.site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription
				},
				{
					property: `og:title`,
					content: title
				},
				{
					property: `og:description`,
					content: metaDescription
				},
				{
					property: `og:type`,
					content: type
				},
				...(image
					? [
							{
								property: `og:image`,
								content: image
							}
					  ]
					: []),
				{
					// Keep the default image as fallback
					property: `og:image`,
					content: data.sscsLogo.publicURL
				}
			]
				.concat({
					name: `keywords`,
					content: keywords.join(`, `)
				})
				.concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	keywords: []
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired
};

export default SEO;
